html, body {
    // font-family: 'Cabin', sans-serif;
    font-family: 'Nunito', sans-serif;
    color: $primary;
    // color: red;
}

[class^="col"] {
    // border: 1px solid black;    
    // border: 1px solid white;
}
