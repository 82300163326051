.dashboard-shortcut {
    
    
    .btn-group {

        & .ng-fa-icon {
            margin-right: .5rem;
        }
        
        & .btn input {
            display: none;
        }
    }
}