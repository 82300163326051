.landing-hero .container {
    position: relative;
    margin: 0 auto;
    max-width: 1300px;
    
        &::before {
            content: "";
            position: absolute;
            top:  -130px;
            left: 0;
            width: 100%;
            min-height: 100vh;
            background-image: url(../../img/shape.svg);
            background-repeat: no-repeat;
            background-size: contain;            
            background-position: 100% 0%;
    
            @media all and (min-width: 768px) {
                top: -90%;
                background-size: 60%;
                // background-position: 100% 0%;
            }
        }
    }

   .shape-holder {
    position: relative;  

    &::before {
        content: "";
        position: absolute;
        top: -240px;
        left: -40%;
        width: 100%;
        height: 100vh;
        background-image: url(../../img/shape2.svg);
        background-repeat: no-repeat;
        background-size: 60%;            
        background-position: 0 20%;

        @media all and (max-width: 768px) {
            display: none;
        }

        @media all and (min-width: 1800px) {
            left: -240px;
            top: -370px;
            background-size: 80%;    
        }
    }
} 

.landing-hero {

    & .fa-github {
        font-size: 4rem;
        margin-bottom: 0.75rem;
    }

    & .section-title {
        // position: relative;
    }

    & img {
        max-width: 100%;
        margin: 0 auto;
        display: block;
    }

    & .btn {position: relative;}

    .btn::after,
    .btn::before {
        content: "";
        position: absolute;
        width: 100px;
        height: 150px;
        background-image: url(../../img/shape-dots.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 0 0%;

        @media all and (max-width: 768px) {
            display: none;
        }
    }
    .btn::after {        
        top: -100px;
        left: -550px;        
    }
        .btn::before {            
            top: 30px;
            left: -130px;                      
            transform: rotate(90deg);
            -moz-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1) rotate(230deg);
            filter: FlipH;
            -ms-filter: "FlipH";    
    }
}