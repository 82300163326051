/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

// @import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700&display=swap');

@import 'assets/styles/base/variables';
@import 'assets/styles/base/base';
@import 'assets/styles/base/buttons';
@import 'assets/styles/base/dashboard-structure';
@import 'assets/styles/base/landing-structure';
@import 'assets/styles/base/landing-structure-temp';
@import 'assets/styles/base/mixins';
@import 'assets/styles/base/typography';

@import 'assets/styles/components/alert';
@import 'assets/styles/components/breadcrumbs';
@import 'assets/styles/components/card';
@import 'assets/styles/components/landing-cards';
@import 'assets/styles/components/landing-clients';
@import 'assets/styles/components/landing-contact';
@import 'assets/styles/components/landing-hero';
@import 'assets/styles/components/landing-testimonial';
@import 'assets/styles/components/common';
@import 'assets/styles/components//dashboard';
@import 'assets/styles/components/error-panel';
@import 'assets/styles/components/filters-panel';
@import 'assets/styles/components/footer';
@import 'assets/styles/components/forms';
@import 'assets/styles/components/helpfiles';
@import 'assets/styles/components/loading';
@import 'assets/styles/components/modal';
@import 'assets/styles/components/navigation';
@import 'assets/styles/components/pagination';
@import 'assets/styles/components/metrics';
@import 'assets/styles/components/pricing-panel';
@import 'assets/styles/components/profile';
@import 'assets/styles/components/results-header-list';
@import 'assets/styles/components/help-overlay';
@import 'assets/styles/components/settings';
@import 'assets/styles/components/sidebar';
@import 'assets/styles/components/shortcut-panel';
@import 'assets/styles/components/signin';
@import 'assets/styles/components/table-results';
@import 'assets/styles/components/workspace';

// formly-field { display: block; } 