
.alert-danger {
    background-color: rgba($alert, .1);
    border: none;
    color: $alert;

    & .close {
        color: $alert;
    }

    & .close:hover {
        color: $alert;
    }
}

.toast-body {
    background-color: $neutral70;
    // color: $white;
}

// success", "info", "warning" 


// danielscss
// note we have already  .orbi-results-sortablecolumn in _table-results.scss and _results-header-list

// .orbi-results-col-sortable {
//     background-color: #0000FF;
// }

// .orbi-results-col-filterable {
//     background-color: #00FF00;
// }

// .orbi-results-col-activesort-asc {
//     color: #880000;
// }

// .orbi-results-col-activesort-desc {
//     color: #CC0000;
// }

// .orbi-results-col-activefilter {
//     color: #00FF00;
// }


