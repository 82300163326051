.orbi-main-nav.navbar {
    // background-color: $neutral70;
    z-index: 90;

    & .navbar-brand img {
            width:180px ;
    }

    & .navbar-toggler-icon fa-icon {
        display: none;
    }

    & .nav-item {
        display: flex;
        align-items: center;
        border-left: 1px solid #fff;

        & .selected {
            background-color: $secondary;
        }
        
        & :hover {
            background-color: $secondary ;
        }
    
        & .nav-link,
        & .nav-link:hover {
            color: inherit;
            cursor: pointer;
        }

      

        & .nav-link img {
            width: 20px;
            margin-right: .3rem;
            margin-top: -.3rem;
            display: none;
        }
        
        & fa-icon {
            margin-right: .5rem;
            display: none;
        }
    
        &:hover {
            // background-color: $secondary;
        }
    }
    
}

// NAVIGATION PROFILE DROPDOWN 

// dropdown button height fix 
.nav-item div,
.nav-item .nav-profile,
.nav-item .dropdown-toggle {
    height: 100%;
}

.nav-profile {

    & .dropdown-toggle {
        border: none;
        border-radius: 0;

        & fa-icon {
            margin-right: .5rem;
        }
    }

    & .dropdown-menu {
        padding: 0;
        border-color: transparent;

        &:hover {
            // border-color: $neutral70;
        }
    }

    & .dropdown-item {
        padding: 0.75rem 1.5rem;
        background-color: $neutral70;

        &:hover,
        &:focus {
            background-color: $neutral50 !important;
            color: $primary;
        }
    }

    & .user-nav-item .dropdown-item {
        &:first-child, 
        &:last-child {display: none;}        
    }

    // HIGHTLIGHTED BUTTON 
    // #dropdownFormOrgSelector {
    //     background-color: $primary;
    //     color: $white;
    // }
}



