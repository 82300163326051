// .loadpanel-1,
// .loadpanel-2 {
//     display: none !important;
// }

.loadpanel-2 {
    display: flex;
    flex-wrap: wrap;

    & .img-holder {
        text-align: center;
        & img {
            max-width: 350px;
            width: 100%;
        }
    }
}

.loadpanel-3 {
    display: flex;
    flex-wrap: wrap;

    & .table-holder {
        width: 100%;
    }    

    & .img-holder {
        text-align: center;

        & img {
            max-width: 350px;
            width: 100%;
        }
    }
}

.progress-holder {
    align-items: center;
}

.progress {
    margin: .5rem 0 1rem;
    width: 100%;

    .progress-bar {
        background-color: $highlight;
    }
}