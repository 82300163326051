// BILLING CYCLE SWITCH 
.orbi-bill-cycle {
    display: flex;
    align-items: center;
}

.orbi-cycle-select {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}

// PRICING CARDS 
.pricing-card {
    text-align: center;
    // width: 400px;
    border: none;
    transition: all .5s ease;
    box-shadow: 0px 10px 10px 0px rgba($primary,0.15);
    margin-bottom: 1rem;
    
    &:hover {
        box-shadow: 0 20px 60px rgba(0,0,0,.3);
        transform: scale(1.02);
    }

    & .card-header {        
        color: $white;
        padding-top: 1rem;
        padding-bottom: 1rem;
        border: none;
        transition: all .5s ease;
    }
    
    &--price1 .card-header {background-color: $price1;}
    &--price2 .card-header {background-color: $price2;}
    &--price3 .card-header {background-color: $price3;}

    &--price1:hover .card-header {background-color: darken($price1, 10%);}
    &--price2:hover .card-header {background-color: darken($price2, 10%);}
    &--price3:hover .card-header {background-color: darken($price3, 10%);}
    
    & .card-title {
        font-size: 1.375rem;        
        font-weight: 200;
        text-transform: uppercase;
    }

    & .card-price {
        font-size: 3.125rem;   

        & span {
            display: block;
            font-size: .875rem;
            margin-top: -.5rem;            
        }
    }

    & .card-body {
        & .list-group-item {
            & fa-icon {
                margin-right: .5rem;
                width: 20px;
                height: 20px;
                border: 3px solid;
                display: inline-block;
            }
            & .fa-check {color: $highlight;}
            & .fa-times {color: $alert;}
        }

        & .list-group-flush > .list-group-item:hover {
            border-color: transparent;
            // color: #000;
        }   
    }
}

// FAQ SECTION 

.faq-row {
    margin-bottom: 2rem;
    padding: 2rem;
    box-shadow: 0px 10px 10px 0px rgba($primary,0.15); 
    transition: all .5s ease;

    &:hover {
        box-shadow: 0px 10px 20px 0px rgba($primary,0.45); ;
    }

    & .faq__head {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    .faq__title {
        font-weight: 700;
    }

    .faq__text {
        margin-bottom: 0;
    }
}