.dashboard-main__settings {

    & .btn-group > * {flex-basis: 100%;}

    & .app-setting-buttons {
        margin-bottom: 1rem;
    
        & span {
            margin-right: .5rem;
        }
        & label {
            margin-bottom: 0;
        }
    }

    & .form-group {
        & .orbi-filters-list {
            display: flex;
            flex-wrap: wrap;
            & .form-control {
                width: auto;
            }
            & .input-group-text {
                display: inline-flex;
                color: $white !important;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
        
        & a.btn-primary {color: $white !important;}
    }
}

.dashboard-main__intro-panel {
    background-color: $secondary;
    padding: 1rem;

    & h2 {flex-basis: 100%;}

    & .orbi-filters-tabs ul {
        margin-bottom: 0;
    }

    & a {
        text-decoration: underline !important;
        cursor: pointer;
    }
}

