.card-reasons {
    text-align: center;
    transition: all 0.5s ease;
    box-shadow: 0px 10px 10px 0px rgb(66 73 83 / 15%);
    padding: 1rem 0;
    margin-bottom: 1rem;
    height: 100%;

    & .card-body {
        display: flex;
        flex-direction: column;

        & > * {
            flex: 1;
        }
    }

    & .card-title {
        font-size: 1.375rem;
        font-weight: 200;
        text-transform: uppercase;
    }

    & .img-holder {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    & .card-img {
        width: auto;
        height: 90px;
    }
}