.nav-pills {
    background-color: $primary;
    color: $white;
    height: 100%;
    padding: 0;
    // bug fix 
    padding-top: .01rem; 

    & .nav-link {        
        padding: 0 .5rem;
        border-radius: 0;
        transition: all .3s ease;
        display: flex;
        align-items: center;
        min-height: 48px;
        position: relative;

        &.orbi-nav-link-title {
            margin-top: .5rem;
            text-transform: uppercase;
            padding-left: 1.5rem;
        
            &::before {
                content: "";
                position: absolute;
                width: 8px;
                height: 8px;
                left: .5rem;
                background-color: $highlight;
            }

            & a {               
                color: $white;
                cursor: pointer;
                text-decoration: none;
            }

            &:hover,
            &.active {
                background-color: $primary-dark;
            }
        }

        &.orbi-nav-link-subtitle {
            background-color: transparent;            
            display: flex;
            align-items: center;   
            padding-left: 1rem; 
            @media all and (max-width: 1200px) {
                flex-wrap: wrap;
            }
            

            & a {
                flex: 1;
                padding: 0.75rem .5rem 0.75rem 0rem;
                color: $white;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &:hover,
            &.active {
                background-color: $primary-dark;
            }

            & .badge-holder {
                flex: 0;
            }

            @media all and (max-width: 1200px) {
                & .badge-holder {
                    flex: 1 1 100%;
                    display: flex;
                    margin-bottom: 0.5rem;
                    margin-top: -0.5rem;
                }
            }
        }

        & [class*="fa-"] {
            margin-right: .5rem;
            margin-left: auto;
          }
    }
} 




// .my-custom-class .tooltip-inner {
//     background-color: darkgreen;
//     font-size: 125%;
//   }

// .my-custom-class .arrow::before {
//     border-top-color: darkgreen;
//   }

// .orbi-sidebar-subtitle {}

.badge-holder {
    // flex: 0;

    & .badge-success {background-color: $highlight;}
    & .badge-danger {background-color: $alert; margin-left: .5rem;}
    & .badge-live {background-color: $primary;}
    & .badge-not-live {border: 1px solid $neutral50; color: $primary;}
}

.nav-pills .nav-link [class*=fa-] {
    margin-right: 0.25rem;
}

.nav-link a {
    text-decoration: none;
}
