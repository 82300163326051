.orbi-filters-tabs {
    padding: 0;

    &.navbar {
        background-color: transparent;

        & .nav-tabs {
            border-bottom: none;
    
            & .nav-items {
            }
    
            & .nav-link {
                color: $primary;
                margin-right: .5rem;
                border: 1px solid transparent;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
    
                &.active {
                    background-color: #fff;
                }
    
                // & span {
                //     font-weight: 900;
                //     text-transform: uppercase;
                // }
            }
        }
    }
}

.orbi-filters-pane {
    background: #fff;

    &.tab-content > .tab-pane {
        padding: 3rem 2rem;
    }

    & .orbi-section-title {
        @include section-title;
    }

    & .repo-list-outer {
        display: flex;

        & > * {
            flex-basis: 100%;
            margin-bottom: 1rem;
        }
    }

    @media all and (max-width: 1024px) {
        & .repo-list-outer {
            flex-wrap: wrap;
        }
    }
}

.orbi-filters-list {

    & ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;  
        align-items: center;  

        & li {
            margin-right: .5rem;
            margin-bottom: .5rem; 

            & fa-icon {
                margin-left: .5rem;
            }

            & span {
                font-weight: 600;
            }
        }
    }

    .orbidropdownoptions li {
            flex-basis: 100%;
    }
}

.orbi-toggle-filter {
    display: flex;
    align-items: center;
    
    & h4 {
        font-size: 14px;
        margin-bottom: 0;

        &:hover {
            cursor: pointer;
        }
    }
}


.date-selection {
    display: flex;

    & label {margin-bottom: 0;}

    & .globalfilter-date-inthepast {
        display: flex;
    }

    & .globalfilter-date-intherange {
        display: flex;

        & .input-group {
            display: flex;
            align-items: center;
        }
    }
}

.filter-star {
    display: flex;
    align-items: center;

    & > span {
        position: absolute;
    }

    & .btn {
        padding-left: 2rem;
    }

    &:hover .btn {
        background: $primary-dark;
        border: transparent;
        color: $white;
    }

    &:hover fa-icon,
    & fa-icon,
    & fa-icon.selected {
        color: $white;
    }
}