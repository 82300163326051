    // Added prefix to card. Too global causing conflicts 
.prefix-card {
    background-color: $secondary;
    transition: all .5s;
    text-align: center;
    margin-bottom: 2rem; 

    &:hover {
        box-shadow: 0 20px 60px rgba(0,0,0,.3);
        transform: scale(1.03);
    }

    & fa-icon {
        margin: 0 auto;
        font-size: 2rem;
    }
}



.card {
    background-color: $neutral60;
    border: none;
    border-radius: 0;

    & canvas {
        // max-height: 300px;
    }
}

.dashboard-charts {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 1rem;
    grid-template-areas: 
    "ch1"
    "ch2"
    "ch3"
    "ch4"
    "ch5"
    "ch6";
}   


@media all and (min-width: 768px) {
    .dashboard-charts {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 
        "ch1 ch2"
        "ch3 ch4"
        "ch5 ch6";
    }       
}

@media all and (min-width: 1024px) {
    .dashboard-charts {
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 1fr 1fr;
    }   
    
    .chart-1 {grid-area: ch1; grid-column: 1 / span 4; grid-row: 1;}
    .chart-2 {grid-area: ch2; grid-column: 5 / span 4; grid-row: 1;}
    .chart-3 {grid-area: ch3; grid-column: 9 / span 4; grid-row: 1/span 2;}
    .chart-4 {grid-area: ch4; grid-column: 1 / span 3; grid-row: 2;}
    .chart-5 {grid-area: ch5; grid-column: 4 / span 3; grid-row: 2;}
    .chart-6 {grid-area: ch6; grid-column: 7 / span 2; grid-row: 2;}
    
}

