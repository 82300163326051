.landing-contact {

    & img {
        max-width: 70%;
        margin: 0 auto;
        display: block;
    }

    & h3 {
        font-size: 1.2rem;
    }
}

// .contact-list {
//     margin: 0;
//     padding: 0 0 0 2rem;
//     list-style: none;

//     & li {
//         // border: 1px solid ;
//         padding: .5rem 0;
//         display: flex;
//         align-items: center;

//         & a {
//             color: inherit;
//         }
//     }

//     & .fas {
//         margin-left: -1.6rem;
//         position: absolute;
//     }

//         max-width: 100%;
//     margin: 0 auto;
//     display: block;
// }