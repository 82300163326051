footer {
    background: darken($primary, 10);
    color: $white;
    padding: 1rem 2rem;
}

.footer-list {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    
    & li {
        &:not(:first-child) {margin-left: 1rem;}

        & a {color: $white;}

        &.logo {
            margin-right: auto;
            margin-bottom: 1rem;
            flex-basis: 100%;

            & img {
                width: 180px;
            }
        }

        &:last-child,
        &:nth-child(3) {display: none;}
    }
}

@media all and (min-width: 767px) {
    
    .footer-list li {
        &.logo {        
            margin-bottom: 0;
            flex-basis: auto;
        }
    }
}