$primary: #424953;
$primary-dark: darken($primary,50);

$secondary: #E0E3E7;
$neutral15: #646c77;
$neutral40: #a9b1bc;
$neutral50: #c4c9d0;
$neutral60: #e0e3e7;
$neutral70: #F4F6F8;

$white: #fff;

$highlight: #9ED16A;
// $highlight10: lighten($highlight,10);
// $highlight20: lighten($highlight,20);
// $highlight30: lighten($highlight,30);
// $highlight40: lighten($highlight,40);
// $highlight-dark10: darken($highlight,10);
$highlight-dark20: darken($highlight,20);
$alert: #D84452;

$price1: #4A87DA;
$price2: #D84452;
$price3: #E7553E;







// USED IN COOKIES SITE 
$bg-grey: #F8F8F8;