.dashboard-shortcut {
    display: block;
}

.container-fluid {
    height: 100vh;
    display: flex;
    flex-direction: column;

    > * {
        flex: 0 0  auto;
    }

    & .jumbotron.signin-container {
        flex: 1 1 auto;
        overflow-y: scroll;

        &::before {
            content: "";
            position: absolute;
            top: 0%;
            left: 0%;;
            width: 100%;
            min-height: 100vh;
            background-image: url(../../img/shape-dots.svg);
            background-repeat: no-repeat;
            background-size: 50%;            
            background-position: 80% 20%;
            // transform: rotate(235deg);
            opacity: 0.5;
        }

        &::after {
            content: "";
            position: absolute;
            top: -30%;
            left: -70%;
            width: 100%;
            min-height: 100vh;
            background-image: url(../../img/shape2.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 100% 0%;
            transform: rotate(365deg);
            opacity: 0.5;
        }
    }

    & .img-holder {
        margin-bottom: 2rem ;

        & img {
            max-width: 200px;
        }
    }
}