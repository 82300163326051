.profile {
    display: flex;
    text-align: center;
    // border: 2px solid red;

    @media all and (max-width: 767px) {
        // text-align: center; 
    }

    & .img-holder img {
        border-radius: 100%;
        max-width: 100%;
    }

    & > * {
        // border: 1px solid ;
    }

}

// .profile-left {text-align: center;}
.profile-right {
    background-color: $neutral70;
    text-align: left;
}

.profile__list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.perso-list {    
    & ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        width: 100%;
        border-bottom: 1px solid white;
        background: $neutral70;

        & li {            
            padding: .75rem .5rem;
            margin-right: .5rem;

            &.project-label {
                margin-right: auto;
            }
        }
    }
}

.test-hide {display: none;}
.test-show {margin-top: .05rem;}