@mixin pos-center {
    display: flex;
    align-items: center;
    justify-content: center;
}


@mixin list-reset {
    list-style: none;
    padding:0;
    margin: 0;
}

@mixin section-title {
    font-size: 1.5rem;
}
