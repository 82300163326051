.ngx-pagination {}

.ngx-pagination .current {
    background: $primary !important;
    color: #fff !important;
}

.ngx-pagination a:hover, .ngx-pagination button:hover {
    background: $bg-grey !important;
}

.pagination {
    .page-item.active .page-link {
        background-color: $primary;
        border-color: $primary;
    }

    .page-link {
        color: $primary;

        &:hover {
                // z-index: 2;
                // color: var(--bs-pagination-hover-color);
                // background-color: var(--bs-pagination-hover-bg);
                // border-color: var(--bs-pagination-hover-border-color);
                // background-color: red;
        }
    }
}