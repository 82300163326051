.breadcrumb {
    background-color: transparent;
}

.card-header {
    background-color: transparent;
}

.breadcrumb-item.active {
    color: $primary;
    // color: red;
}