.dash-tile {
    
        background-color: $secondary;
        padding: .5rem 1rem 1rem 1rem;
        // margin-bottom: 3rem;
    
}

.isComplete {
    background-color: $highlight;
}
