// .dash-structure {
//     margin: 1rem 0;;
    
//     @media all and (min-width: 992px) {
//         margin: 2rem 15%;
//      }

//     & > * {
//         // border: 1px solid ;
//     }
// }

.dashboard-sidebar {}

.dashboard-main__filters {
    
    & .inner-filters {
        background-color: $secondary;
        padding: 1rem 1rem;
        // margin-bottom: 3rem;
    }
}

.dashboard-main__results {
    & .table-scroll-holder {
        width: 100%;
        overflow-y: scroll;
    }
}

.dashboard-shortcut {
    // border: 1px solid ;  
}