.section-title {
    font-size: 2rem;
    text-transform: uppercase;
    margin-bottom: 0;
}

.orbi-section-title {
    font-size: 2.3rem;
    // font-weight: 700;
    width: 100%;
}

.orbi-section-subtitle {
    font-size: 1rem;
    
    // text-transform: uppercase;

    & span {
        margin-right: .5rem;
        font-weight: 700;
    }
}

.section-link {
    color: $primary;

    &:hover {
        color: $primary-dark;
    }
}

.modal-title {
    // background-color: yellow;
    font-size: 1rem;
}