.carousel-inner {
    height: 12rem;
}

.landing-testimonial {
    & .quote {
        font-size: 1.25rem;
        margin-bottom: 1rem;
        text-align: center;
    }
}

.testimonial-profile {
    display: flex;
    justify-content: center;
}

.testimonial-image {
    border-radius: 50%;
    overflow: hidden;
    display: none;
}

.testimonial-list {
    text-align: center;
    padding: 0;
    margin: 0;
    list-style: none;

    & > * {
        // border: 1px solid ;
    }

    & .name {
        text-transform: uppercase;
    }
    & .position {
        font-size: .825rem;
    }
    & .company {
        font-size: .75rem;
    }
}

.carousel-indicators {
    & li {
        background-color: $neutral40;

        &.active {
            background-color: $primary;
        }
    }
}

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%);
}