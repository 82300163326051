.orbi-results-header-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    padding: 0;
    list-style: none;

    & li:not(:last-child) {
            margin-right: .5rem;        
    }

    & .breadcrumb-item span {
        white-space: nowrap;
        margin-right: .5rem;
    }

    & .input-group > .form-control {
        width: 400px;
    }
}

.orbi-results-filters-recap {
    display: flex;
    padding: 0;
    list-style: none;

    & li {
        margin-right: 1rem;

        & span {
            font-weight: 600;
        }
    }
}

.orbi-results-sortablecolumn {
    background-color: $neutral70;
    border-right: 1px solid $neutral50 !important;
    border-bottom: 1px solid $neutral50 !important;
}


// .orbi-results-sortablecolumn-aqua {
//     background-color:aquamarine;
// }

// .orbi-results-col-sortable {
//    background-color: #0000FF;
// }


// renamed from .orbi-results-sortablecolumn {
.orbi-results-col-sortable {
    background-color: $neutral70;
    border-right: 1px solid $neutral60 !important;
    // border-bottom: 1px solid $neutral50 !important;
}

.orbi-results-col-filterable {
    // background-color: #00FF00;
}

.orbi-results-col-hidden {
    display: none;
}

.orbi-results-col-activesort-asc {
    color: #880000;
}

.orbi-results-col-activesort-desc {
    color: #CC0000;
}

.orbi-results-col-activefilter {
    color: yellow;
}


// .orbi-results-col-filterable {background-color: yellow;}
.orbi-results-col-activesort .icon-sort,
.orbi-results-col-activefilter .icon-filter {
    background-color: $primary;
    color: $white;
}

// .orbi-results-col-activesort-desc .icon-sort {background-color: $highlight;}


.orbi-col-wide {min-width: 150px;}


.resize-handle {
    width: 6px;
    height: 100%;
    right: 0;
    top: 0;
    position: absolute;
    cursor: col-resize;
    background-image: linear-gradient(45deg, $white 25%, $neutral60 25%, $neutral60 50%, $white 50%, $white 75%, $neutral60 75%, $neutral60 100%);
    background-size: 5.66px 5.66px;
}

.orbi-results-col-sortable .resize-handle {
    background-image: linear-gradient(45deg, $neutral70 25%, $neutral60 25%, $neutral60 50%, $neutral70 50%, $neutral70 75%, $neutral60 75%, $neutral60 100%);
    background-size: 5.66px 5.66px;
}
