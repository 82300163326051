.side-panel-outer {
    // border: 3px solid red;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 100vh;
    pointer-events: none;
    overflow: hidden;
    display: none;
}

.orbi-side-overlay {
    position: absolute;
    top: 0;
    right: -50%;
    width: 50%;
    background-color: white;
    height: 100vh;
    z-index: 600;
    transition: all .3s ease;
    padding: 1rem;
    box-shadow: 0 20px 60px rgb(0 0 0 / 30%);
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    
    &.open {right: 0;}
}

.help-overlay-header {
    // background-color: khaki;
    display: flex;
    justify-content: space-between;
    flex: 0;
}
.help-overlay-body {
    padding: 2rem;
    flex: 1;
}
.help-overlay-footer {
    background-color: lightblue;
    flex: 0;
}

.help-row {
    margin-bottom: 1rem;
    padding: 1rem 0;
    // box-shadow: 0px 10px 10px 0px rgba($primary,0.15); 
    transition: all .5s ease;
    // border: 1px solid;

    // &:hover {
    //     box-shadow: 0px 10px 20px 0px rgba($primary,0.45); ;
    // }

    & .help__head {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    .help__title {
        font-weight: 700;
    }

    .help__text {
        margin-bottom: 0;
    }
}

