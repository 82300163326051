
// Change spinner height and colour 

.modal-spinner {
    & .modal-content {
        align-content: center;
        justify-content: center;
        height: calc(100vh - 3.5rem);
        border: none;
        background: none !important;
    }

    & .spinner-border {
        width: 3rem;
        height: 3rem;
        color: #fff;
    }    
}


.tempClass {
    display: block !important;
    background-color: #fff;
    border: 2px solid $primary;
    position: absolute;
    top: 0;
    left: 50%;

} 

// Sign-in Modal 
#signup-modal {
    height: 95vh;
    overflow: scroll;

    & >  * {
        display: flex;
        flex-direction: column;
        height: 100%;

        &  >  *  {
            flex: 1 1 auto;
        }
    }

    .modal-body {
        overflow-y: scroll;

        & .jumbotron  {
            padding: 1rem 2rem  0;
        }
    }
}