.client-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;

    & li {
        margin-left: 0.5rem;
        margin-bottom: 0.5rem;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    & li img {
        height: 15px;
    }
}