.btn-primary {
    background: $primary;
    border: transparent;
    text-transform: uppercase;

    &:hover {
        background: $primary-dark;
        border: transparent;
    }

    &:focus, &.focus {
        background: lighten($primary, 10%) !important;
        border: transparent !important;
        box-shadow: none !important;
    }

    &:active, &.active {
        background: $highlight !important;
        border: transparent !important;
        // color: $primary !important;
    }

    &:disabled,
    &.disabled {
        background: $neutral40 !important;
        border: transparent;
        color: rgba($white, .7);
    }
}


.btn-secondary {
    background: $neutral40;
    border: inherit;

    &:hover {
        background: lighten($neutral40, 10%);
        border: inherit;
    }

    &:active, &:focus {
        background: lighten($neutral40, 10%) !important;
        border: inherit !important;
    }
}

.btn-light {
    background: transparent;
    border-color: transparent;

    &--border {
        border: 1px solid $secondary; 
    }
}

.btn-filter {
    background: $neutral40;
    border: transparent;
    color: $secondary;

    &.selected-not fa-icon {
        display: none;
    }

    &:hover {
        background: $primary-dark;
        border: transparent;
        color: $white;
    }

    &:focus, &.focus {
        background: $neutral40;
        border: transparent !important;
        color: $secondary;
        box-shadow: none;
    }

    &:active, &.active, &.selected {
        background: $highlight !important;
        border: transparent !important;
        color: $white !important;
    }

    &.selected fa-icon{
            display: inline-block;
    }

    &:disabled {
        background: $neutral40 !important;
        border: transparent;
        color: rgba($white, .7);
    }
}

