.signin {

    &-container {
        // background: #F8F8F8;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;

        & > * {
            // box-shadow: 0 20px 60px rgba(0,0,0,.3);
            min-width: 70%;
        }

        & .tab-content > .tab-pane {
            background: $neutral70;
            border-top: none;
            border-bottom-left-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
            padding: 1rem;
        }    
        
        & .form-control {
            border-left: 5px solid $highlight;
        }

        & .nav-tabs {
            border-bottom: 1px solid $neutral70;
        
            & .nav-item {
                flex: 1;
                text-align: center;  
                margin-bottom: -3px;    
                
                &:first-child {margin-right: .5rem;}
        
                & .nav-link {
                    color: $primary;
                    background: $secondary;
        
                    &.active {
                        border: none;
                        background: $neutral70;
                    }
                }
            }            
        }

        & .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
            color: $primary;
            background-color: $white;
            border-color: $primary $primary #fff;
        }

        & .access-panel {

            @media all and (min-width: 768px) {
                display: flex;
            }

            & .tile {
                flex: 1;
                margin: 0 .5rem 1rem .5rem;
                padding: 2rem 1rem;
                background-color: $white;
                box-shadow: 0px 10px 10px 0px rgba($primary,0.15);
                z-index: 999;
                display: flex;
                flex-direction: column;
                transition: .3s ease;

                &:hover {
                    transform: scale(1.03);
                }

                & ul {
                    flex-grow: 1;
                    text-align: left;

                    & li  {
                        margin-bottom: .3rem;
                    }
                }
            }
        }
    }
}












// EXTERNAL SIGN IN 

// .banner-customizable {
//     background-color: #fff;
// }

// .submitButton-customizable {
//     font-size: 14px;
//     font-weight: bold;
//     margin: 20px 0px 10px 0px;
//     height: 40px;
//     width: 100%;
//     color: #fff;
//     background-color: $primary;
//     border-color: none;
// }