input.form-control {    
    border: 1px solid $secondary; 
    border-radius: 0;   
    // height: calc(1.5em + 1.15rem + 2px); 
    max-width: 250px;
    
    &:focus {
        border-color: inherit;
        box-shadow: none;
    }
}

select.custom-select {
    margin: 0 .5rem 0 0;
    border-radius: 0;
    width: auto;

    &:focus {
        border-color: inherit;
        box-shadow: none;
    }
}

.orbi-w-3char {
    flex-grow: 0;
    flex-basis: 60px;
}

.input-group-text {
    background-color: $primary;
    color: $white;
    border: none;
}

.form-check-input {
    accent-color: $highlight;
    width: 1.5em;
	height: 1.5em;
	margin-right: 0.65rem;
}
