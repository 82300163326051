.metrics-holder {
    // outline: 4px solid red;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;

    &  .card-metrics {
        padding: 1rem;
        background-color: $neutral70;
        border: 1px solid $neutral70;
        font-weight: 600;
        text-transform: uppercase;
        flex: 1 1 auto;
        flex-basis: calc(33% - 1rem);;
        transition: all .3s ease;

        &:hover {
            box-shadow: 0px 10px 10px 0px rgba($primary,0.15);
            border: 1px solid $neutral60;
            transform: scale(1.03);
        }
    }
}