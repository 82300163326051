.landing-hero {
    display: flex;
    align-items: center;
    min-height: 50vh;
    padding: 0;
    position: relative;

    
}

.landing-whyorbi {
    background-color: $neutral70;
    display: flex;
    align-items: center;
    min-height: 70vh;
    padding: 4rem 0;
}
.landing-pricing {
    // display: flex;
    display: none;
    align-items: center;
    min-height: 70vh;
    padding: 4rem 0;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -10%;;
        width: 100%;
        min-height: 100vh;
        background-image: url(../../img/shape.svg);
        background-repeat: no-repeat;
        background-size: contain;            
        background-position: 100% 0%;
        transform: rotate(235deg);
        opacity: 0.5;
    }
}

.landing-contact {
    display: flex;
    flex-wrap: wrap;
    // align-items:center;
    align-content: center;
    min-height: 70vh; 
    padding: 4rem 0;  

    & > * {
        flex-basis: 100%;
    }
    
}

.landing-testimonial {
    background-color: $neutral70;
    // display: flex;
    display: none;
    align-items: center;
    padding: 1rem 0;
    min-height: 20vh; 
}

.landing-client {
    background-color: $primary;
    color: $white;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    min-height: 20vh; 
}
footer {

}