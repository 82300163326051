// set table col widths based on first row widths which improves performaance for big tables
// .orbi-table 

.orbi-table th,
.orbi-table td {
    color: $primary;

    & [iconname=faExclamationTriangle] {color: $alert;}
    & [iconname=faCheck] {color: $highlight-dark20;}
    & [iconname=faBan] {color: $neutral50;}
}

// Hide row status icon Status Column
.orbi-table td.status-col app-orbiicon {display: none;}
.orbi-table td.status-col.results-false app-orbiicon[iconname^="faExclamationTriangle"],
.orbi-table td.status-col.results-true app-orbiicon[iconname^="faCheck"],
.orbi-table td.status-col.results-na app-orbiicon[iconname^="faBan"],
.orbi-table td.status-col.results-dontknow app-orbiicon[iconname^="faQuestionCircle"] {
    display: inline-block;
}

// Show row status icon Relevance Column
.orbi-table td[class*="relevance-"] app-orbiicon {display: none;}
// .orbi-table td[class*="relevance-false"] app-orbiicon[iconname^="faExclamationTriangle"],
// .orbi-table td[class*="relevance-true"] app-orbiicon[iconname^="faCheck"],
// .orbi-table td[class*="relevance-na"] app-orbiicon[iconname^="faStar"] {
//     display: inline-block;
// }


.orbi-table td.impacts-col span[class*="metadata-"] {
    font-weight: 600;
}
// .orbi-table span.metadata-impact { 
//     // color: $alert; 
//     font-weight: 600;
// }
// .orbi-table span.metadata-ok { 
//     // color: green; 
//     font-weight: 600;
// }
// .orbi-table span.metadata-na { 
//     // color: grey; 
//     font-weight: 600;
// }


.orbi-table thead th {
    font-size: 14px;
    text-transform: capitalize;
    border-bottom: none;
    border-top: none;
    position: relative;

    & .th-inner {
        display: flex;
        align-items: flex-end;

        & .label {flex: 1;}

        & .button-holder {
            display: flex;
            position: absolute;
            right: 10px;
        }

        & .icon-sort,
        & .icon-filter {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .75rem;
        }
    }
}

.orbi-table  tr {
    background-color: $white;

    &:last-child {border-bottom: none;}
}

.orbi-table td {
    border-right: 1px solid $secondary;
}

.orbi-table td a {
    color: $primary;
    text-decoration: underline;
}

// Add false / true border 
.orbi-table tbody tr.results-false td:first-of-type {border-left: 10px solid $alert;}
.orbi-table tbody tr.results-true td:first-of-type {border-left: 10px solid $highlight-dark20;}
.orbi-table tbody tr.results-na td:first-of-type {border-left: 10px solid $neutral50;}
.orbi-table tbody tr.results-dontknow td:first-of-type {border-left: 10px solid $neutral50;}

// Show hide icon 
.orbi-table tbody tr.results-false .highlight--true {display: none;}
.orbi-table tbody tr.results-true .highlight--false {display: none;}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: $white;
}

// TABLE ROW hover 
.table-row-hover tbody tr:hover {
    background-color: $highlight;
    border: none;

    & td,
    & td a {color: #fff;}

    & .highlight--true {color: $white;}

    & td .badge-pill {color: #fff !important; border: 1px solid $white;}
}

.table-row-hover-light tbody tr:hover {
    background-color: $neutral70;
    border: none;  
}

// Resutls table colour codes 
.wbauditresult {
    background-color: $neutral60;
    border-right: 1px solid $neutral50 !important;
    border-bottom: 1px solid $neutral50 !important;
}

.wbaudittest {
    background-color: $neutral70;
}

.wbpayload {
    background-color: aqua;
    // display: none;
}


// TABLE ROW HOVER EFFECT
tr:hover td.wbauditresult,
tr:hover td.wbaudittest,
tr:hover td.orbi-results-sortablecolumn  {
    background-color: inherit;
    border-right-color: inherit !important;
    border-bottom: none !important;
}

.resultsColMaxWidth {
    max-width: 125px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.resultsColMaxWidth:hover{
    overflow: visible; 
    white-space: normal; 
    position: relative;
}

.hovericons  {display:none;}
.resultsColMaxWidth:hover .hovericons {display: block; cursor: pointer;}
.hovericons:active  {transform: scale(1.2);}

/* goal here is when cells are crowded, you can hover over cell you want and 
full text will display, and text wont be distored by text in adjacent cells
we can use wrapping in some cases here but in other cases that wont work because 
there are no spaces and the content cant be read at narrow width (e.g. a commit message) */

td.resultsColMaxWidth:hover > span {
    background-color: $highlight-dark20;
    position: absolute;
    top: 10px;
    left: 20px;
    // padding: 1rem;    
    background-color: #fff;
    color: $primary;
    box-shadow: 0px 10px 20px 0px rgba($primary,0.45);     
    word-break: break-all;
    display: flex;
    min-width: 0px;
    width: 300px;
    display: flex;
    align-items: center;
    // flex-direction: column;

    & span {
        // width: 300px;
        padding: 1rem;  
    }

    & a {
        color: $primary !important;
    }

    & .hovericons {
        // margin-right: .3rem;
        margin-left: 1rem;
    }
}

// .orbi-col-wide {min-width: 150px;}



.orbiThResizer {
    width: 10px;
    height: 100%;
    right: 0;
    top:0;
    position: absolute;
    cursor: ew-resize !important;
}

.orbiThResizerActive {
    border-right: 1px dotted #000;
}

// datatypes in the results column
.orbiiconcol {
    max-width: 65px;
    width: 65px;
}

.orbidate {
    max-width: 50px;
}

.orbinumeric {
    max-width: 50px;
}

.orbitext-short {
    max-width: 100px;
}

.orbitext-long{
    max-width: 150px;
}

// COLUMN HIGHLIGHT 

.wbheader {
    position: relative;

    &::before {
        position: absolute;
        height: 20px;
        top: 2px;
        right: 34px;
        font-size: .75rem;
        color: rgba($primary, .8);
    }
    &::after {
        content: "\2139";
        position: absolute;
        top: 0;
        right: 20px;
        color: rgba($primary, .8);
    }
}
.wbheaderwbidentity::before {content: "Identity"; }
.wbheaderwbdate::before {content: "Dates"; }
.wbheaderwbauditresult::before {content: "Audit"; }
.wbheaderwbauditrelevant::before {content: "Audit Relevant"; }
.wbheaderwbaudittest::before {content: "Audit Tests"; }
.wbheaderwbattribute::before {content: "Attributes";}

.wbheaderwbidentity, 
.wbidentity {background-color: rgba($primary, .1) !important;}
.wbheaderwbdate,
.wbdate {background-color: rgba($primary, .2) !important;}
.wbheaderwbauditresult,
.wbauditresult {background-color: rgba($primary, .1) !important;}
.wbheaderwbauditrelevant,
.wbauditrelevant {background-color: rgba($primary, .2) !important;}
.wbheaderwbaudittest,
.wbaudittest {background-color: rgba($primary, .1) !important; }
.wbheaderwbattribute,
.wbattribute {background-color: rgba($primary, .2) !important;}


.orbiimpactscol {
    width: 30rem;
    max-width: 30rem;
    min-width: 30rem;
}

.orbi-table td[class*="relevance-"] {
    & ul {
        list-style: none;
        margin: 0;
        padding-left: 0;
    }
}

.impacts-col {

    // Comma separate the impact labels 
    & .impacts-col-rowflag ~ .impacts-col-rowflag::before {
        content: ', '; 
    }

    & ul {
        list-style: none;
        margin: 0;
        padding-left: 0;
    }
}